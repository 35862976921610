
import {mapGetters} from "vuex";
import LoadingSpinner from "./loading-spinner";

export default {
    name: "Subnavs",
    components: {LoadingSpinner},
    computed: {
        ...mapGetters({
            currentCompany: "app/currentCompany",
        }),
        isComposeActive() {
            return this.$route.name === "emails-id" || this.$route.name === "emails-compose";
        },
        isTargetingGroupActive() {
            return this.$route.name === "companies-targeting-groups-id";
        },
    }
}
